/* eslint-disable */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import LogoImg from 'assets/images/logo.svg';
import { RootState } from 'store/store';
import { callsActions } from 'store/calls';
import { translate } from 'localizations';
import { removeAuthToken } from 'store/user/auth.thunks';
import { useAppSelector } from 'hooks/redux';
import { searchSlice } from 'store';
import { LabelSelect, OldInterfaceLink, Tooltip } from 'components/common';
import { OptionType } from 'components/common/Selects/MultiValueSelect/multiValuesSelect';
import BellSelect from 'components/RealtimeTasks/BellSelect';
import { PersonSvg } from 'components/Icons/PersonSvg';
import { getAllReports } from 'store/reports/actions';
import { useStyles } from './Header.jss';
import ToggleButtons from '../ToggleButtons/ToggleButtons';
import useToggleButtons from './hooks/useToggleButtons';
import { useSnackbar } from 'notistack';
import { getBaseCallsData, getBaseCallsDataReports } from 'store/calls/actions';
import { exportsActions } from 'pages/Calls/components/CallsHeader/const';
import { getActionFiles, getTaskStatus, tasksSlice, TaskType } from 'store/tasks/tasks.slice';
import { createSnackbarOptions } from '../Snackbar/Snackbar';
import { useCurrentUser } from 'hooks';
import LangSelector from 'features/LangSelector/LangSelector';
import { LangType } from 'store/lang/lang.slice';
import { RouteParams } from 'pages/Settings/types';

const Header: React.FC = () => {
	const { lang } = useParams<RouteParams>();
	const { language } = useAppSelector((state) => state.lang);

	const currentLang = lang || language;

	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();

	const user = useAppSelector((state) => state.user.user);
	const childUser = useAppSelector((state) => state.user.childUser);
	const isAuth = useAppSelector((state) => state.user.isAuth);
	const pathArray = history.location.pathname.split('/');
	const path = pathArray[3];
	const accessRights = useAppSelector((state) => state.user.accessRights);

	const userPathName = childUser ? childUser.id : user ? user.id : '_';

	const toggleButtons = useToggleButtons(currentLang);

	const tasks = useAppSelector((state) => state.tasks.realtimeTasks);
	const { enqueueSnackbar } = useSnackbar();
	const sort = useAppSelector((state: RootState) => state.calls.sort);
	const mode = useAppSelector((state) => state.search.calls.mode);
	const search_filter_hash = useAppSelector((state) => state.search.reports.search_filter_hash);

	const currentUser = useCurrentUser();
	// роутинг
	const [alignment, setAlignment] = React.useState('');
	const handleRouteChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
		setAlignment(newAlignment);
	};

	useEffect(() => {
		const hasReports = !['admin', 'manager'].includes(currentUser?.role as string) && accessRights?.report;
		if (hasReports) {
			const fetchAllReports = async () => {
				await dispatch(getAllReports());
			};
			fetchAllReports();
		}
	}, [currentUser?.id]);

	useEffect(() => {
		const pageSetter = (location: any) => {
			const page = location.pathname.split('/')[3];
			if (page === 'dictionaries' || page === 'checklists') setAlignment('tags');
			if (page === 'report') setAlignment('reports');
			else setAlignment(page);
		};
		history.listen((location) => {
			pageSetter(location);
		});
		pageSetter(history.location);
	}, [history.location]);

	const logout = () => {
		dispatch(removeAuthToken());
		dispatch(callsActions.setCalls(false));
		dispatch(searchSlice.actions.searchReset());
		history.push(`/${currentLang}/auth`);
		window.location.reload();
	};

	const login = () => {
		history.push(`/${currentLang}/auth`);
	};

	const onLogoClick = (): void => {
		history.push(`/${currentLang}/${userPathName}/calls`);
		window.location.reload();
	};

	// задачи
	const progressTasksForMode = async (task: TaskType) => {
		if (task.action && exportsActions.includes(task.action)) {
			// скачивание звонков (экспорт аудио, экспорт расшифровки, экспорт звонков)
			await dispatch(getActionFiles({ taskId: task.task_id, action: task.action }));
		} else {
			// все остальные действия
			if (mode === 'REPORTS') {
				// @ts-ignore
				await dispatch(getBaseCallsDataReports({ page: 1, hash: search_filter_hash }));

				// почему не обновляется звонок открытый
			} else {
				await dispatch(getBaseCallsData({ page: 1, sort: sort.sort, sortDesc: sort.sortDesc }));
			}
		}
	};

	const realTimeTasksHandler = (task: TaskType, index: number) => {
		if (!task.startProgress) {
			dispatch(tasksSlice.actions.replaceRealtimeTask({ task: { ...task, startProgress: true }, index }));

			if (['SUCCESS', 'FAILURE'].includes(task.status)) {
				// Задача уже завершена, обрабатываем сразу
				progressTasksForMode(task);
				dispatch(tasksSlice.actions.removeRealtimeTask(index));
				enqueueSnackbar(
					null,
					createSnackbarOptions({
						time: 2000,
						text: translate('taskComplete', currentLang),
						type: 'success',
					}),
				);
			} else {
				// Запускаем интервал для отслеживания статуса задачи
				const timer = setInterval(async () => {
					const taskData = await dispatch(getTaskStatus(task.task_id));
					const newTask = taskData.payload;

					if (newTask) {
						dispatch(
							tasksSlice.actions.replaceRealtimeTask({
								task: { ...newTask, startProgress: true },
								index,
							}),
						);
					} else {
						clearInterval(timer);
						enqueueSnackbar(
							null,
							createSnackbarOptions({
								time: 2000,
								text: translate('taskCanceled', currentLang),
								type: 'info',
							}),
						);
					}

					if (!['PROGRESS', 'PENDING'].includes(newTask.status)) {
						clearInterval(timer);
						progressTasksForMode(task);
						dispatch(tasksSlice.actions.removeRealtimeTask(index));
						enqueueSnackbar(
							null,
							createSnackbarOptions({
								time: 2000,
								text: translate('taskComplete', currentLang),
								type: 'success',
							}),
						);
					}
				}, 2000);
			}
		}
	};

	useEffect(() => {
		tasks.map((task, index) => {
			realTimeTasksHandler(task, index);
		});
	}, [tasks]);

	return (
		<div className={classes.headerWrapper}>
			<div className={classes.headerInner}>
				<div className={classes.headerLeftBlock}>
					{/* Логотип */}
					<div style={{ cursor: 'pointer' }} onClick={onLogoClick} className={classes.headerIconWrapper}>
						<img className={classes.headerIcon} height={55} src={LogoImg} alt="Imot.io" />
					</div>
					{/* Навигация */}
					{isAuth && (
						<ToggleButtons
							alignment={alignment}
							handleRouteChange={handleRouteChange}
							toggleButtons={toggleButtons}
							language={currentLang}
							userPathName={userPathName}
							accessRights={accessRights}
						/>
					)}
				</div>
				<div className={classes.headerRightBlock}>
					<div style={{ marginTop: '2px', display: 'flex', alignItems: 'center' }}>
						{isAuth && (
							<>
								{/* история задач */}
								<div style={{ marginLeft: '5px' }}>
									<BellSelect />
								</div>
								{/* старый интерфейс */}
								<div style={{ marginLeft: '5px' }}>
									<OldInterfaceLink />
								</div>
							</>
						)}
						{/* логин/логаут */}
						<div style={{ marginLeft: '5px' }}>
							{isAuth && (
								<Tooltip title={translate('profile', currentLang)} placement="bottom">
									<div style={{ marginRight: '10px', cursor: 'pointer' }}>
										<LabelSelect
											value={[]}
											options={[
												{ label: translate('profile', currentLang), value: 'profile' },
												{ label: translate('logout', currentLang), value: 'logout' },
											]}
											notPlusMinus
											valueHandler={(values: OptionType[]) => {
												if (values[0].value === 'logout') logout();
												else if (values[0].value === 'profile')
													history.push(
														`/${currentLang}/${
															user ? user.id : '_'
														}/settings?settingsParam=usersProfile`,
													);
											}}
											customLabel={
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<IconButton size="small">
														<PersonSvg fill="#A3AEBE" />
													</IconButton>
												</div>
											}
										/>
									</div>
								</Tooltip>
							)}
						</div>
						{/* смена языка */}
						{isAuth && (
							<LangSelector
								user={user}
								childUser={childUser}
								path={path}
								language={currentLang as LangType}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
